/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/header"
import Footer from "./Footer/footer"
import "./layout.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/global.scss"
const Layout = ({ children, layout }) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        siteConfig(publicationState: LIVE) {
          instagram_link
          linkedin_link
          twitter_link
          facebook_link
          footer_links {
            menu_link {
              custom_link
              label
              link_type
              target
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
        }
        socialLink(publicationState: LIVE) {
          Office_Address
          Office_Contact_No
          footer_menu {
            title
            footer_links {
              custom_link
              label
              target
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
        }
        headerMenu(publicationState: LIVE) {
          Left_Menu {
            custom_link
            label
            target
            theme
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
          }
          Right_Menu {
            custom_link
            label
            target
            theme
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
          }

          left_main_menu {
            id
            label
            menu_items {
              id
              label
              link_type
              target
              custom_link
              menu_item {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
            sub_menu_item {
              id
              label
              link_type
              target
              custom_link
              menu_item {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }

          right_main_menu {
            id
            label
            menu_items {
              id
              label
              link_type
              target
              custom_link
              menu_item {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
            sub_menu_item {
              id
              label
              link_type
              target
              custom_link
              menu_item {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        menudata={data?.glstrapi?.headerMenu}
        white={
          layout === "without_banner_template" ||
          layout === "form_template" ||
          layout === "content_only_template"
            ? true
            : false
        }
      />
      <main>{children}</main>
      <Footer
        siteconfig={data?.glstrapi?.siteConfig}
        footerdata={data?.glstrapi?.socialLink}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
