import React from "react";
import "./footer.scss"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import { Link } from "gatsby";
import Starberry from "../../images/starberry.svg";
import { FLogo, Instagram, Twitter, Facebook, Linkedin } from "../icons"
import ContentBlock from "../Content/content";
import { GetPageURL, Clients } from "../Common/utils"
//import Cookies from "../Cookies/Cookies"
import CookieConsent from "../CookieConsent/CookieConsent";
import SubscribeForm from "../Forms/SubscribeForm"
const Footer = ({ footerdata, siteconfig }) => {
    const year = new Date().getFullYear();
    return (
        <footer>
            <CookieConsent/>
            <Container>
                <Row>
                    <Col className="foo-logo" lg={{ span: 4, order: 1 }} md={{ span: 6, order: 3 }} xs={{ span: 12, order: 4 }}>
                        <div className="footer-logo">
                            <FLogo />
                        </div>
                        <ul className="link-list">
                        {siteconfig?.footer_links?.menu_link?.map((item, i)=> {
                              return(                           
                                <li key={i}>
                                <Link target={item?.target === "new_window" ? "_blank" : ""} to={GetPageURL(item.menu_item, item.custom_link)}>
                                 {item?.label}{siteconfig?.footer_links?.menu_link?.length-1 === i ? "" : <>&nbsp;/&nbsp;</>} 
                                </Link>
                            </li>
                            )
                        })}
                       <li> <a href="javascript:void(0);" id="open_preferences_center">Update cookies preferences</a></li>
                        
                        </ul>
                        <p class="copy-txt">&copy; {year} Crisp Cowley.<br />All Rights Reserved</p>
                        <p class="copy-txt"><span className="starberry-link">Site by <Link href="http://starberry.tv/" target="_blank" className="btn-text-link"><img src={Starberry} alt="Starberry" className="stb-logo" /></Link></span></p>
                    </Col>
                    <Col lg={{ span: 2, order: 3 }} xs={{ span: 6, order: 2 }} md={{ span: 6, order: 2}} >
                        <h6>{footerdata?.footer_menu[0]?.title}</h6>
                        <ul className="explore-pages">
                            {footerdata?.footer_menu[0]?.footer_links?.map((item, index) => {
                                return (
                                    <>
                                    {item.label==="Proprties"?
                                    <li key={index}>
                                        <Link target={item?.target === "new_window" ? "_blank" : ""} to={item.custom_link}>
                                            {item?.label}
                                        </Link>
                                    </li>:
                                    (item.label==="Lettings"||item.label==="Tenants")?
                                    <li key={index}>
                                    <a target={item?.target === "new_window" ? "_blank" : ""} href={item.custom_link}>
                                        {item?.label}
                                    </a>
                                    </li>
                                    :
                                    <li key={index}>
                                        <Link target={item?.target === "new_window" ? "_blank" : ""} to={GetPageURL(item.menu_item, item.custom_link)}>
                                            {item?.label}
                                        </Link>
                                    </li>
                                    }
                                    </>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col lg={{ span: 2, order: 2 }} xs={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}>
                        <h6>contact</h6>
                        <ContentBlock Content={footerdata?.Office_Address} />
                        {/* <p>{footerdata?.Office_Contact_No}</p> */}
                        <p className="footer-contact-num"><a href={`tel:+44 ${footerdata?.Office_Contact_No}`}>{footerdata?.Office_Contact_No}</a></p>

                    </Col>
                    <Col className="mob-top-40 newsletter" lg={{ span: 4, order: 4 }} xs={{ span: 12, order: 3}} md={{ span: 6, order: 4 }}>
                        {/* <h6>NEWSLETTER</h6> */}
                        {/* <Form>
                            <SubscribeForm />
                        </Form> */}
                        
                        {/* <p className="txt">By clicking Submit, you agree to our <Link to="/terms-of-use/">Terms </Link> <span style={{margin:"0 1px"}}>&</span><Link to="/privacy-policy/"> Privacy Policy</Link>.</p> */}
                        <ul className="social-links">
                            <li><a href={siteconfig?.instagram_link} target="_blank"><Instagram /></a></li>
                            <li><a href={siteconfig?.twitter_link} target="_blank"><Twitter /></a></li>
                            {/* <li><Link to={siteconfig?.facebook_link}><Facebook /></Link></li> */}
                            <li><a href={siteconfig?.linkedin_link} target="_blank"><Linkedin /></a></li>
                        </ul>
                        {Clients &&
                            <div className="logo-sec">
                                {Clients.map((node, i) => (
                                    <a href={node.websiteLink} target="_blank" >
                                        <img src={node.imgUrl} alt={node.alternativeText} />
                                    </a>
                                ))}
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

        </footer>
    )
}

export default Footer