import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Logo, Search, Menu } from "../icons"
import { Button, Container, Offcanvas } from "react-bootstrap"
import { GetPageURL } from "../Common/utils"
import "./header.scss"
import { Helmet } from "react-helmet"
import { useHover } from "@react-hooks-library/core"
import clsx from "clsx"
import $ from "jquery"

const Header = ({ siteTitle, white, menudata }) => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200)
    })
  }, [])

  // $(document).mouseover(function (e) {
  //   console.log($(e.target)) // i just retrieved the id for a demo
  // })

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const MenuItem = ({ target, label, menu }) => {
    const href =
      menu.menu_items?.custom_link === "javascript:void(0)"
        ? { href: menu.menu_items?.custom_link }
        : {
            to: GetPageURL(
              menu.menu_items?.menu_item,
              menu.menu_items?.custom_link
            ),
          }
    return (
      <li className={clsx("menu-item")}>
        <Link target={target === "new_window" ? "_blank" : ""} {...href}>
          {label}
        </Link>
        {menu?.sub_menu_item?.length > 0 && (
          <div className="sub-menu-section-wrap">
            <div className="sub-menu-section">
              {menu.sub_menu_item.map(menuItem => (
                <Link
                  to={GetPageURL(menuItem?.menu_item, menuItem?.custom_link)}
                  className={clsx("sub-menu-item")}
                  target={menuItem?.target === "new_window" ? "_blank" : ""}
                >
                  {menuItem?.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </li>
    )
  }

  const MobileMenuItem = ({ target, label, menu }) => {
    const href =
      menu.menu_items?.custom_link === "javascript:void(0)"
        ? { href: menu.menu_items?.custom_link }
        : {
            to: GetPageURL(
              menu.menu_items?.menu_item,
              menu.menu_items?.custom_link
            ),
          }
    return (
      <>
        {!(menu?.sub_menu_item?.length > 0) && (
          <li className={clsx("menu-item")}>
            <Link target={target === "new_window" ? "_blank" : ""} {...href}>
              {label}
            </Link>
          </li>
        )}

        {menu?.sub_menu_item?.length > 0 &&
          menu.sub_menu_item.map(menuItem => (
            <li className={clsx("menu-item")}>
              <Link
                to={GetPageURL(menuItem?.menu_item, menuItem?.custom_link)}
                className={clsx("sub-menu-item")}
                target={menuItem?.target === "new_window" ? "_blank" : ""}
              >
                {menuItem?.label}
              </Link>
            </li>
          ))}
      </>
    )
  }

  return (
    <header className={scroll ? "" : white ? "" : "fixed-white"}>
      {/* <Helmet>
      <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=gdrh7geu8xaxmrybhiuyxw" async="true"></script>
      </Helmet> */}
      <Offcanvas placement={"end"} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="logo-menu">
              <Link to={"/"}>
                <Logo />
              </Link>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="header-block">
            <ul>
              {menudata?.left_main_menu?.map((item, index) => {
                return (
                  <MobileMenuItem
                    key={index}
                    target={item?.target}
                    label={item?.label}
                    menu={item}
                  />
                )
              })}
              {menudata?.right_main_menu?.map((item, index) => {
                return (
                  <>
                    <li key={index}>
                      <MobileMenuItem
                        key={index}
                        target={item?.target}
                        label={item?.label}
                        menu={item}
                      />
                    </li>
                    {index === 0 && (
                      <li>
                        <Link to={"/meet-the-team/"}>Meet The Team</Link>
                      </li>
                    )}
                  </>
                )
              })}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="logo-menu">
        <Link to={"/"}>
          <Logo />
        </Link>
      </div>
      <div className="d-flex d-lg-none mob-menu">
        <Button>
          <Link to="/property/for-sale/in-bath/">
            <Search />
          </Link>
        </Button>
        <Button onClick={handleShow}>
          <Menu />
        </Button>
      </div>
      <div className={clsx("header-block d-none d-lg-flex")}>
        <div className="left-menu">
          <ul>
            {menudata?.left_main_menu?.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  target={item?.target}
                  label={item?.label}
                  menu={item}
                />
              )
            })}
          </ul>
        </div>
        <div className="right-menu d-none d-lg-flex">
          <ul>
            {menudata?.right_main_menu?.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  target={item?.target}
                  label={item?.label}
                  menu={item}
                />
              )
            })}
            {/* <li>
                <Link to="/property/for-sale/in-bath/"><Search /></Link>
              </li> */}
          </ul>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
